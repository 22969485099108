import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import NavBar from './NavBar';
import About from './About';
import Contact from './Contact';
import Home from './Home';
import Projects from './Projects';
import Work from './Work';
import Footer from './footer';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App/>
    <About/>
    <Projects/>
    <Contact/>
    <Work/>
    <Footer/>
  </React.StrictMode>
);
reportWebVitals();
