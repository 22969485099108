
import './index.css';
import { Link, animateScroll } from 'react-scroll';
// import portfolio from '';
import React from 'react';


  
   
const Home = () => {

    return (
        <>
            <div id="Home">
            <img className="profilepic" src={require(".//IMG_86001.jpg")} /><div className="info-box">

      </div>
            <div class='Welcome'>👋Hi,Welcome.</div>
            <div class='myname'>I'm Harsha Vardhan!</div>
            <div class='mydesc'>
            <div class='Intro1'>    <a href="https://linkedin.com/in/harsha-vardhan-donkada" target="_blank" rel="noopener noreferrer">
                <p className="Intro1-show">&nbsp;An IT professional with 4 years of experience in web development, database,
                     cloud &nbsp;technologies and cyber security.</p>
                        <p className="Intro1-hide">
                            Click here to connect with me on Linkedin  </p> </a>
                            </div>
                <br></br><br></br>
                
                <div class='Intro2'><Link to="projects-container" smooth={true} duration={500}><p className="Intro2-show">&nbsp;An enthusiastic tech geek, passionate about coding, learning latest
                   technologies &nbsp;and solving real-world problems.</p>
                    
                       <p className="Intro2-hide">Click here to view my projects</p></Link>
                        
                </div>
                
                <br></br><br></br>
                
                <div class='Intro3'><Link to="Contact" smooth={true} duration={500}><p className="Intro3-show">&nbsp;Pursued MS in computer science, graduated in May 2024,
                    seeking opportunities &nbsp;to work on challenging projects to contribute to a company's growth.</p>
                  <p className="Intro3-hide">Click here to contact me</p></Link>
                    </div>
                    
                    <br></br> <br></br>

                <div class='Intro4'>
                    <p className="Intro4-show">&nbsp;Loves reading books, hitting the gym, listening music, clicking pictures and &nbsp;turning ideas to tech in free time.</p>
                    <p className="Intro4-hide">Love the way you are exploring, I'm an explorer too!</p>
                    </div>

            </div>

            <p className="desktop_view">*Open in desktop for best view</p>
            </div>
        </>

    );
}
export default Home;