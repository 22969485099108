import React, { useState, useEffect } from 'react';
import './About.css';
import './index.css';
import { Link } from 'react-scroll';

const About = () => {
  const [randomBorderColors, setRandomBorderColors] = useState([]);

  useEffect(() => {
    // Generate random border colors for each skill list item
    const colors = Array.from({ length: 16 }, () => getRandomColor());
    setRandomBorderColors(colors);
  }, []);

  const getRandomColor = () => {
    // Generate random RGB values
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r}, ${g}, ${b})`;
  };

  return (
    <div id='About'>
      <div className='aboutmepage'>
        <h1 className='My-Skills-Heading'>My skills</h1>
        <div className='skill-div'>
          <ul className='skill-list'>
            <h3 className='skill-heading'> Front end </h3>
            <li className='ind-skill-list-fe' style={{ borderColor: randomBorderColors[0] }}>HTML</li> 
            <li className='ind-skill-list-fe' style={{ borderColor: randomBorderColors[1] }}>CSS</li> 
            <li className='ind-skill-list-fe' style={{ borderColor: randomBorderColors[2] }}>JavaScript</li> 
            <li className='ind-skill-list-fe' style={{ borderColor: randomBorderColors[3] }}>React</li>
          </ul>
          <ul className='skill-list'>
            <h3 className='skill-heading'> Back end </h3>
            <li className='ind-skill-list-be' style={{ borderColor: randomBorderColors[4] }}>NodeJS</li> 
            <li className='ind-skill-list-be' style={{ borderColor: randomBorderColors[5] }}>ExpressJS</li> 
            <li className='ind-skill-list-be' style={{ borderColor: randomBorderColors[6] }}>Java</li> 
            <li className='ind-skill-list-be' style={{ borderColor: randomBorderColors[7] }}>Python</li> 
          </ul>
          <ul className='skill-list'>
            <h3 className='skill-heading'> Database </h3>
            <li className='ind-skill-list-db' style={{ borderColor: randomBorderColors[8] }}>MySQL</li>
            <li className='ind-skill-list-db' style={{ borderColor: randomBorderColors[9] }}>MongoDB</li>  
            <li className='ind-skill-list-db' style={{ borderColor: randomBorderColors[10] }}>PostgreSQL</li> 
            <li className='ind-skill-list-db' style={{ borderColor: randomBorderColors[11] }}>Azure</li>  
          </ul>
          <ul className='skill-list'>
            <h3 className='skill-heading'> Tools & technologies </h3>
            <li className='ind-skill-list-tt' style={{ borderColor: randomBorderColors[17] }}>AWS</li> 
            <li className='ind-skill-list-tt' style={{ borderColor: randomBorderColors[12] }}>Networking</li> 
            <li className='ind-skill-list-tt' style={{ borderColor: randomBorderColors[13] }}>Cyber Security</li> 
            <li className='ind-skill-list-tt' style={{ borderColor: randomBorderColors[14] }}>Splunk</li> 
            <li className='ind-skill-list-tt' style={{ borderColor: randomBorderColors[15] }}>GIT</li> 
            <li className='ind-skill-list-tt' style={{ borderColor: randomBorderColors[16] }}>Docker</li>
          </ul>
        </div>
      </div>
      <Link to="Home" smooth={true} duration={500}>
        <button className="circular-button">
          <span className="arrow">&#9650;</span>
        </button>
      </Link>
    </div>
  );
};

export default About;
