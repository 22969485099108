import React from "react";
import './footer.css';
const Footer = () =>{
  return(
    <div id="footer-div">
<footer>
        <div class="copyright-text">    
            <ul>
            <p>&copy; Donkada Harsha Vardhan v24.2</p>
                <li><a className="f-links" href="https://linkedin.com/in/harsha-vardhan-donkada" target="_blank">Linkedin</a></li>
                <li ><a className="f-links" href="https://github.com/donkadaharsha" target="_blank">GitHub</a></li>
                <li><a className="f-links" href="mailto:harsha.donkada@gmail.com" target="_blank">Email</a></li>
                <li><a className="f-links" href="https://www.instagram.com/_____harsha_vardhan_____/?igsh=aHlqb29wNzE1cGJo" target="_blank">Instagram</a></li>
            </ul>
        </div>
        
</footer>
</div>
  );
};
export default Footer;