import React from "react";
import "./Work.css";
const Work = () => {
    return(
        // <body className="work">
        <>
        <div id='Work'>
            <h1 className="Companies">Companies and Clients I positively impacted</h1>
            <div className="Companies-list">
                
                <li><img className="Wipro-logo" src={require(".//Wipro_logo.png")}></img></li>
                <li><img className="Apple-logo" src={require(".//Apple_logo.png")}></img></li>
                <li><img className="Infosys-logo" src={require(".//Infosys_logo.png")}></img></li>
                <li><img className="CSS_Corp-logo" src={require(".//CSS_Corp_logo.png")}></img></li>
                <li><img className="Sophos-logo" src={require(".//Sophos_logo.png")}></img></li>
                <li><img className="OU-logo" src={require(".//OU_logo.png")}></img></li>
                {/* <div class="resume-button"> */}
                {/* <a href=".pdf" download>Download Resume</a> */}
            {/* </div> */}
                
            </div>
            </div>
            </>
        // </body>
    );
};
export default Work;