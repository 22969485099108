import React, { useState } from 'react';
import { useRef } from 'react';
import './NavBar.css';
import About from './About';
import Work from './Work';
import { Link as ReactLink } from 'react-router-dom';
// NavBar.js

import { Link, animateScroll } from 'react-scroll';
import ScrollAnimation from 'react-animate-on-scroll';

const NavBar = () => {
  return (
    <nav>
      <ul>
        <li className='nav-li'><Link to="Home" smooth={true} duration={500} onClick={() => animateScroll.scrollToTop()}>Home</Link></li>
        <li className='nav-li'><Link to="About" smooth={true} duration={500}  onScroll={AnimationPlaybackEvent}>Skills</Link></li>
        <li className='nav-li'><Link to="projects-container" smooth={true} duration={500}>Projects</Link></li>
        <li className='nav-li'><Link to="Work" smooth={true} duration={500}>Work</Link></li>
        <li className='nav-li'><Link to="Contact" smooth={true} duration={500}>Contact</Link></li>
        {/* <li className='nav-li'><a href="portfolio.pdf" target="_blank" download="Harsha-portfolio.pdf">Resume</a> */}
{/* </li> */}
      </ul>
    </nav>
  );
};

export default NavBar;

// const NavBar = () => {
  
//   return (
//     <>
//       <nav>
//         <ul>
//           <li><a href="/Home">Home</a></li>
//           <li><a href="/About">Skills</a></li>
//           <li><a href="/Work">Work</a></li>
//           <li><a href="/Projects">Projects</a></li>
//           <li><a href="/contact" >Contact</a></li>
//         </ul>
//       </nav>
//     </>
//   );
// };

// export default NavBar;

