// src/Contact.js
import React, {useState} from 'react';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message:''
  });

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevents the default form submission
  
    try {
      const response = await fetch("https://getform.io/f/69117f61-5ecc-461a-959a-332323e9749e", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        // Reset the form data after successful submission
        setFormData({
          name: '',
          email: '',
          message: ''
        });
        alert("Your message has been sent.");
      } else {
        // Handle error response
        alert("Error submitting the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };
  


  const handleChange =(event) =>{
    const {name, value} = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  return (
    <div id='Contact'>
    <div className="contact-container">
        
      <form className="contact-form" action="https://getform.io/f/69117f61-5ecc-461a-959a-332323e9749e" method="POST" onSubmit={handleSubmit} autoComplete="off">
      <h2>Send me your message!</h2>
        <h4>Name</h4>
        <input 
        className='input-name' 
        placeholder='Enter your name' 
        type="text" 
        name="name" 
        value={formData.name}
        onChange={handleChange} 
        required/>
        <h4>E-mail</h4>
        <input
        className='input-email' 
        placeholder='Enter your email' 
        type="email" 
        name="email" 
        value={formData.email}
        onChange={handleChange} 
        required/>
        <h4>Message</h4>
        <input 
        className='input-message' 
        placeholder='Enter your message' 
        type="text" name="message" 
        value={formData.message} 
        onChange={handleChange} 
        required
        />
        <button className='form-submit-btn' type="submit">Send</button>
      </form>
     
      
      <div className="contact-links">

        <a className='socials' href="https://www.linkedin.com/in/harsha-vardhan-donkada/" target="_blank" rel="noopener noreferrer">
          <img className='linkedin-logo' src={require(".//linkedin.png")}></img>
          Linkedin
        </a>
        <a className='socials' href="https://github.com/donkadaharsha" target="_blank" rel="noopener noreferrer">
        <img className='linkedin-logo' src={require(".//github.png")}></img>

          Github
        </a>
        
        <a className='socials' href="mailto:harsha.donkada@gmail.com">
        <img className='linkedin-logo' src={require(".//Mail.png")}></img>
          harsha.donkada@gmail.com</a>
        </div>
    </div>  
    </div>
  );
};

export default Contact;
