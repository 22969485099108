import React from "react";
import './Projects.css';

const Projects = () => {
  // Function to generate random RGB color values with transparency
  const getRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    // Adjust the last parameter (alpha) to change transparency (0.1 to 1)
    return `rgba(${r}, ${g}, ${b}, 0.5)`; // Example: 50% transparent
  };

  return (
    <>
      <div id="projects-container">
        <h1 className="My-Proj-Heading">My Projects</h1>
        <ul className="row1">
          <a href="https://github.com/donkadaharsha/Rare-Labs-MarketPlace" target="_blank" rel="noopener noreferrer">
            <li>
              <div className="Proj-heading" style={{ backgroundColor: getRandomColor() }}>
                <h3 className="Heading">NFT Marketplace</h3>
              </div>
            </li>
          </a>
          <a href="https://github.com/donkadaharsha/Blogify-App" target="_blank" rel="noopener noreferrer">
            <li>
              <div className="Proj-heading" style={{ backgroundColor: getRandomColor() }}>
                <h3 className="Heading">Blogify App</h3>
              </div>
            </li>
          </a>
          <a href="https://github.com/donkadaharsha/File_redactor" target="_blank" rel="noopener noreferrer">
            <li>
              <div className="Proj-heading" style={{ backgroundColor: getRandomColor() }}>
                <h3 className="Heading">File Redactor</h3>
              </div>
            </li>
          </a>
          <a href="https://github.com/donkadaharsha/Cuisine_Predictor" target="_blank" rel="noopener noreferrer">
            <li>
              <div className="Proj-heading" style={{ backgroundColor: getRandomColor() }}>
                <h3 className="Heading">Cuisine Predictor</h3>
              </div>
            </li>
          </a>
          <a href="https://github.com/donkadaharsha/Pokemon_board_game" target="_blank" rel="noopener noreferrer">
            <li>
              <div className="Proj-heading" style={{ backgroundColor: getRandomColor() }}>
                <h3 className="Heading">Pokemon Board Game</h3>
              </div>
            </li>
          </a>
        </ul>
        <ul className="row2">
          <a href="https://github.com/donkadaharsha/textToSpeech" target="_blank" rel="noopener noreferrer">
            <li>
              <div className="Proj-heading" style={{ backgroundColor: getRandomColor() }}>
                <h3 className="Heading">Text-to-Speech</h3>
              </div>
            </li>
          </a>
          <a href="https://github.com/donkadaharsha/WeatherApp" target="_blank" rel="noopener noreferrer">
            <li>
              <div className="Proj-heading" style={{ backgroundColor: getRandomColor() }}>
                <h3 className="Heading">Weather App</h3>
              </div>
            </li>
          </a>
          <a href="https://github.com/donkadaharsha/quizApp" target="_blank" rel="noopener noreferrer">
            <li>
              <div className="Proj-heading" style={{ backgroundColor: getRandomColor() }}>
                <h3 className="Heading">Quiz App</h3>
              </div>
            </li>
          </a>
          <a href="https://github.com/donkadaharsha/passwordgenerator" target="_blank" rel="noopener noreferrer">
            <li>
              <div className="Proj-heading" style={{ backgroundColor: getRandomColor() }}>
                <h3 className="Heading">Password Generator</h3>
              </div>
            </li>
          </a>
          <a href="https://snakesaga.netlify.app/" target="_blank" rel="noopener noreferrer">
            <li>
              <div className="Proj-heading" style={{ backgroundColor: getRandomColor() }}>
                <h3 className="Heading">Snake Saga</h3>
              </div>
            </li>
          </a>
        </ul>
        <div className="github-button">
          <a href="https://github.com/donkadaharsha" target="_blank" rel="noopener noreferrer">View more on GitHub</a>
        </div>
      </div>
    </>
  );
};

export default Projects;
